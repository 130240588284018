<template>
  <v-container>
    <v-card>
      <v-toolbar>
        <v-toolbar-title>Accommodations Request Admin</v-toolbar-title>
        <v-spacer></v-spacer>
        <term-select-menu :term-query="termQuery"></term-select-menu>
      </v-toolbar>
      <v-data-table :items="requests" :headers="headers"></v-data-table>
    </v-card>
  </v-container>
</template>
<script>
import { ref } from '@vue/composition-api'

export default {
  components: {
    TermSelectMenu: () => import('@/components/system/termSelectMenu')
  },
  setup (props, { root }) {
    const items = ref([])
    const headers = ref([])

    return {
      items,
      headers
    }
  }
}
</script>
